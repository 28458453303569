import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleApiCall } from "../../helpers/handleApiCall";
import bedService from "../../services/BedService";

export const findBeds = createAsyncThunk(
    'findBeds',
    handleApiCall(({siteId, status, type, page}, { getState }) => {
        const { page: pageRedux } = getState().findBeds;
        return bedService.findBeds({ siteId, status, type, page: page || pageRedux});
    }),
);

export const findClients = createAsyncThunk(
    'findClients',
    handleApiCall(async (data) => await bedService.findClients(data))
);

const initialState = {
    filters: {
        siteId: '',
        type: null,
        status: 1,
    },
    beds: null,
    isLoading: true,
    page: 1,
    totalPageCount: null,
    totalBedsAvailable: null,
};

const slice = createSlice({
    name: 'findBeds',
    initialState,
    reducers: {
        setPage(state, action) {
            state.page = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
            state.page = 1;
        },
    },
    extraReducers: {
        [findBeds.pending]: (state) => {
            state.isLoading = true;
        },
        [findBeds.fulfilled] : (state, action) => {
            const { items, page, totalPageCount, totalBedsAvailable } = action.payload;
            state.beds = items;
            state.page = page;
            state.totalPageCount = totalPageCount;
            state.totalBedsAvailable = totalBedsAvailable;
            state.isLoading = false;
        },
        [findBeds.rejected]: (state) => {
            state.isLoading = false;
        }
    }
});

export const {
    setPage,
    setFilters,
} = slice.actions;

const findBedsSlice = { reducer: slice.reducer, initialState };
export default findBedsSlice;