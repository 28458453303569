import { DragAndDropUploadZone } from '../../../components/DragAndDropUploadZone/DragAndDropUploadZone';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uploadConfig } from '../siteConfiguration';

export const UploadFile = ({onStepComplete}) => {
    const dispatch = useDispatch();
    const { siteId } = useParams();

    return (
        <DragAndDropUploadZone
            onDrop={async (files) => {
                await dispatch(uploadConfig(siteId, files));
                onStepComplete && onStepComplete();
            }}
            
        />
    );
};
