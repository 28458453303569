import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle, Stack } from '@mui/material';
import { DragAndDropUploadZone } from '../../../components/DragAndDropUploadZone/DragAndDropUploadZone';
import { uploadReconciliation } from '../manageBeds';

export const UploadFile = ({ onStepComplete }) => {
    const dispatch = useDispatch();
    const { siteId } = useParams();
    const { error } = useSelector((state) => state.manageBeds);

    return (
        <Stack spacing={2}>
            <DragAndDropUploadZone
                onDrop={async (files) => {
                    const result = await dispatch(uploadReconciliation(siteId, files));
                
                    if (result.wasSuccessful === false) return;

                    onStepComplete && onStepComplete();
                }}
            />
          {error && <Alert severity="error"><AlertTitle>{error}</AlertTitle>Please correct the file and upload try again</Alert>}  
        </Stack>
    );
};
