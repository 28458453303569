import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleApiCall } from "../../helpers/handleApiCall";
import bedService from "../../services/BedService";

export const findJuvareBeds = createAsyncThunk(
    'findJuvareBeds',
    handleApiCall((data) => bedService.findJuvareBeds(data))
);

const initialState = {
    filters: {
        siteCode: '',
    },
    items: null,
    isLoading: true,
    page: 1,
    totalPageCount: null,
};

const slice = createSlice({
    name: 'juvare',
    initialState,
    reducers: {
        setPage(state, action) {
            state.page = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
            state.page = 1;
        },
        setItems(state, action) {
            state.items = action.payload;
        },
    },
    extraReducers: {
        [findJuvareBeds.pending]: (state) => {
            state.isLoading = true;
        },
        [findJuvareBeds.fulfilled] : (state, action) => {
            const { items, page, totalPageCount } = action.payload;
            state.items = items;
            state.page = page;
            state.totalPageCount = totalPageCount;
            state.isLoading = false;
        },
        [findJuvareBeds.rejected]: (state) => {
            state.isLoading = false;
        }
    }
});

export const {
    setPage,
    setFilters,
    setItems,
} = slice.actions;

const juvareSlice = { reducer: slice.reducer, initialState };
export default juvareSlice;