import './ManageBedsPage.scss';
import React from 'react';
import SidebarLayout from '../../layouts/SidebarLayout';
import { Routes, Route } from 'react-router-dom';
import { ManageBedsRightPane } from './components/ManageBedsRightPane';
import { SitesSidebar } from '../../components/SitesSidebar/SitesSidebar';


const ManageBedsPage = () => {
    return (
        <SidebarLayout 
            sideBar={SitesSidebar}
            content={Content}
            shouldOnlyShowConfiguredSites={true}
        />
    );
};

const Content = () => {
    return (
        <Routes>
            <Route index element={<ManageBedsRightPane />} />
            <Route path="/:siteId" element={<ManageBedsRightPane />} />
        </Routes>
    );
};

export default ManageBedsPage;