import { getStoredApiAccessToken } from '../features/auth';
import ApiService from './ApiService';

class BedService extends ApiService {
    async findBeds(data) {
        return this.get('/api/v1/beds', data);
    }

    async findClients(data) {
        return this.get('/api/v1/sites/search', data);
    }

    async uploadReconciliation(data, files, onProgress) {
        const url = `/api/v1/beds/reconciliation/upload?siteId=${data?.siteId}`

        return await this.postMultipartForm(url, {}, files, onProgress);
    }

    async validateReconciliation(data) {
        const { siteId, fileReference, config, importSettings } = data;
        const url = `/api/v1/beds/reconciliation/${fileReference}/validate?siteId=${siteId}&importSettings=${importSettings}`
        return await this.post(url, config);
    }

    async finalizeReconciliation(data) {
        const { siteId, fileReference, config, importSettings  } = data;
        const url = `/api/v1/beds/reconciliation/${fileReference}?siteId=${siteId}&importSettings=${importSettings}`
        return await this.post(url, config);
    }

    async findJuvareBeds(data) {
        return this.get('/api/v1/juvare/statuses', data);
    }

    async findBedLogs(data) {
        return this.get('/api/v1/beds/activities', data);
    }
}

const bedService = new BedService();
bedService.setHost(window.environment.API_URL);
bedService.setTokenResolver(getStoredApiAccessToken);

export default bedService;