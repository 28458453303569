import {
    Alert,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BedType } from '../../enums/BedType';
import { useSearchParams } from 'react-router-dom';
import { ClientsAutocomplete } from '../../components/ClientsAutocomplete';

export const FindBedsFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialClientId = searchParams.get('siteId');

    const totalBedsAvailable = useSelector((state) => state.findBeds.totalBedsAvailable);
    const isLoading = useSelector((state) => state.findBeds.isLoading);

    const [type, setType] = useState(0);
    const [status, setStatus] = useState(0);
    const [client, setClient] = useState(null);
    const siteId = client?.siteId;

    const syncSearchParams = useCallback(() => {
        const keys = ['siteId', 'type', 'status'];
        const values = [siteId, type, status];

        keys.forEach((key, index) => {
            if (!!values[index] || values[index] === 0) {
                searchParams.set(key, values[index]);
            } else {
                searchParams.delete(key);
            }
        });

        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, siteId, type, status]);

    useEffect(() => {
        syncSearchParams();
    }, [siteId, type, status, syncSearchParams]);

    return (
        <Stack direction="row" spacing={2}>
            <ClientsAutocomplete
                onChange={(newClient) => setClient(newClient)}
                value={client}
                initialClientId={initialClientId}
            />
            <Select value={type} onChange={(e) => setType(e.target.value)} size="small">
                {BedType.getEntries().map(([_, value]) => (
                    <MenuItem key={value} value={value}>
                        {BedType.getDescription(value)}
                    </MenuItem>
                ))}
            </Select>
            <Select value={status} onChange={(e) => setStatus(e.target.value)} size="small">
                <MenuItem value={0}>All Statuses</MenuItem>
                <MenuItem value={1}>Available</MenuItem>
                <MenuItem value={3}>Out Of Service</MenuItem>
                <MenuItem value={2}>Occupied</MenuItem>
            </Select>
            <Alert
                icon={<BedIcon fontSize="small" sx={{alignItems: "center"}} />}
                severity="info"
                sx={{ paddingTop: 0, paddingBottom: 0, alignItems: 'center' }}
            >{`Beds Available: ${isLoading ? "..." : totalBedsAvailable}`}</Alert>
        </Stack>
    );
};
