import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const LoadingOverlay = ({ text = "Loading..." }) => {
    return (
        <Backdrop open sx={{ color: '#fff' }}>
            <Stack spacing={4} sx={{ alignItems: "center" }}>
                <Typography>{text}</Typography>
                <CircularProgress color="inherit" />
            </Stack>
        </Backdrop>
    );
};

LoadingOverlay.propTypes = {
    text: PropTypes.string
};