import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { setStoredApiAccessToken } from "./localStorage/tokens";
import { useAuth } from "./useAuth";

/**
 * Component that once logged in, will silently fetch the
 * API access token needed to make backend calls.
 * This should happen any time the user navigates to a new page.
 */
export const ApiAccessTokenInitializer = () => {
    const { getApiAccessToken, isInProgress } = useAuth();
    const location = useLocation();
    
    useEffect(() => {
        if (isInProgress)
            return;
        
        const runGetApiAccessToken = async () => {
            const accessToken = await getApiAccessToken();
            setStoredApiAccessToken(accessToken);
        };

        runGetApiAccessToken();
    }, [isInProgress, location, getApiAccessToken]);

    return null;
};