import React from 'react';
import { getStoredRedirectUrl } from './localStorage/redirectUrl';
import { Navigate } from 'react-router-dom';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useAuth } from './useAuth';

/**
 * Component that display loading UI until authentication is complete
 */
export const AuthCallbackRedirect = () => {
    const { isAuthenticated, isInProgress } = useAuth();

    return (isAuthenticated && !isInProgress) ? (
        <Navigate to={getRedirectUrl()} /> 
    ) : (
        <LoadingOverlay />
    );
};

function getRedirectUrl() {
    let redirectUrl = getStoredRedirectUrl();
    if (!redirectUrl) {
        console.warn('Invalid redirect URL, redirecting to root instead.');
        redirectUrl = '/';
    }
    return redirectUrl;
}