import { BaseEnum } from "./BaseEnum";

export const BedType = {
    None: 0,
    Rehabilitation: 1,
    Psychiatric: 2,
    ChemicalDependency: 3,
    GeneralMedicalSurgicalAdult: 4,
    GeneralMedicalSurgicalPediatric: 5,
    Obstetrics: 6,
    Orthopedic: 7,
    Oncology: 8,
    Neurological: 9,
    MedicalICU: 10,
    SurgicalICU: 11,
    CardiacICU: 12,
    PediatricICU: 13,
    NeonatalICU: 14,
    PostCriticalCareStepdown: 15,
    BurnCare: 16,
    WomenAndInfant: 17,
    Other: 18,

    descriptions: {
        0: 'All Bed Types',
        3: 'Chemical Dependency',
        4: 'General Medical Surgical - Adult',
        5: 'General Medical Surgical - Pediatric',
        10: 'Medical - ICU',
        11: 'Surgical - ICU',
        12: 'Cardiac - ICU',
        13: 'Pediatric - ICU',
        14: 'Neonatal - ICU',
        15: 'Post-Critical Care Stepdown',
        16: 'Burn Care',
        17: 'Women and Infant',
    },

    ...BaseEnum
}