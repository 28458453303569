import { useAuth } from "./useAuth";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { ApiAccessTokenInitializer } from "./ApiAccessTokenInitializer";
import { useRedirectToLogin } from "./useRedirectToLogin";

/**
 * Wrap this component around protected pages that require the 
 * user to be authenticated. When unauthenticated the page will
 * render a spinner and redirect the user to the login provider.
 */
export const RequiresAuth = ({ children }) => {
    const { isAuthenticated, isInProgress } = useAuth();
    
    // hook that redirects to login page if unauthenticated
    useRedirectToLogin();
    
    return (isAuthenticated && !isInProgress) ? (
        <>
            <ApiAccessTokenInitializer />
            {children}
        </>
    ) : (
        <LoadingOverlay />
    );
}