import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { apiRequest, loginRequest } from "./authConfig";
import { useEnvironment } from "../environment";
import { InteractionStatus } from "@azure/msal-browser";
import { clearStoredApiAccessToken } from "./localStorage/tokens";
import { clearStoredRedirectUrl } from "./localStorage/redirectUrl";

/**
 * Hook that provides a wrapper around all the MSAL (Microsoft
 * Authentication Library) functionality for easy use throughout
 * the application without having to direct link to MS packages.
 */
export const useAuth = () => {
    const { inProgress, instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated(); 
    const { PORTAL_NAME } = useEnvironment();

    const isInProgress = inProgress !== InteractionStatus.None;

    const login = useCallback(() => {
        instance.loginRedirect(loginRequest).catch((error) => console.error(error));
    }, [instance]);

    const logout = useCallback(() => {
        instance.logoutRedirect({ postLogoutRedirectUri: '/' + PORTAL_NAME }).catch((error) => console.error(error));

        clearStoredApiAccessToken();
        clearStoredRedirectUrl();
    }, [instance, PORTAL_NAME]);

    const getApiAccessToken = useCallback(async () => {
        if (!accounts?.length) return;

        const response = await instance.acquireTokenSilent({
            ...apiRequest,
            account: accounts[0],
        });
        return response.accessToken;
    }, [instance, accounts]);

    return { 
        isAuthenticated, 
        isInProgress, 
        getApiAccessToken,
        login, 
        logout, 
    };
}