export const BaseEnum = {
    getDescription(value) {
        if (this.descriptions) {
            const description = this.descriptions[value];
            if (description) return description;
        }
        return this.parseValue(value);
    },

    getEntries() {
        const entries = cleanEntries(Object.entries(this));
        return entries;
    },

    parseValue(value) {
        const entries = this.getEntries();
        const entry = entries.find((entry) => entry[1] === value);
        return entry ? entry[0] : null;
    },
}

const cleanEntries = (entries) => {
    return entries.reduce((accumulator, currentValue) => {
        const ignored = [
            'descriptions',
            'enhancedDescriptions',
        ];
        const isDefined = currentValue[1] !== null && currentValue[1] !== undefined;
        const isNotAFunction = {}.toString.call(currentValue[1]) !== '[object Function]';
        const isNotIgnored = !ignored.includes(currentValue[0]);
        if (isDefined && isNotAFunction && isNotIgnored) {
            accumulator.push(currentValue);
        }
        return accumulator;
    }, []);
}