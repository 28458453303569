
import './App.scss';
import AppRootComponents from './AppRootComponents';
import AppRouter from './AppRouter';

const App = () => {
	return (
		<AppRootComponents>
			<AppRouter />
		</AppRootComponents>
	);
}

export default App;
