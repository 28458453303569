import { BaseEnum } from "./BaseEnum";

export const BedImportSettings = {
    DoNotUpdate: 0,
    SetToAvailable: 1,
    SetToOccupied: 2,

    descriptions: {
        0: "Don't Update",
        1: 'Set all to Available',
        2: 'Set all to Unavailable',
    },

    ...BaseEnum
}