import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    Typography,
    List,
    ListItem,
    Stack,
    TextField,
    Alert,
    Autocomplete
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { columnAliases, setConfigToSave } from '../manageBeds';

export const MapColumns = ({onStepComplete}) => {
    const dispatch = useDispatch();
    const selectedSite = useSelector((state) => state.sites?.selectedSite);
    const { fileReference, columns, configurations: initialConfigurations, initialValues } = useSelector(
        (state) => state.manageBeds,
    );

    const [configurations, setConfigurations] = useState(initialConfigurations);

    const validation = configurations?.reduce((acc, config) => {
        if (config.isRequired) {
            const key = config.id.toString();
            acc[key] = Yup.object().required(`${config.name} is required`);
        }
        return acc;
    }, {});

    const { values, errors, isSubmitting, handleSubmit, handleBlur, setFieldValue } = useFormik({
        initialValues,
        validationSchema: Yup.object().shape(validation),
        onSubmit: (values) => {
            const config  = {}
            Object.entries(values).forEach(([key, value]) => {
                if (value && value.key) {
                    config[key] = value.key;
                }
              });

            const data = {
                siteId: selectedSite.siteId,
                fileReference,
                config,
                importSettings: null
            }

            dispatch(setConfigToSave(data))
            onStepComplete && onStepComplete(data);
        },
    });

    useEffect(() => {
        const bedCodePresent = Object.values(values).some((val) => val?.label === 'Bed Code' || columnAliases['Bed Code']?.includes(val?.label));
        setConfigurations((prev) => prev.map((config) => {
            if (config.name === 'Room Name' || config.name === 'Bed Name') {
                return {
                    ...config,
                    isRequired: !bedCodePresent,
                };
            }

            if(config.name === 'Bed Code') {
                return {
                    ...config,
                    isRequired: bedCodePresent,
                };
            }

            return config;
        }
        ));

    }, [values])

    const columnOptions = useMemo(() => {
        if(!values || !columns) return [];
        const usedColumnLabels = Object.values(values).filter((val) => !!val).map(({label}) => label);
        return columns.filter(({ label }) => !usedColumnLabels.includes(label));
    }, [values, columns]);

    const hasErrors = errors && Object.keys(errors).length > 0;

    return (
        <Stack>
            <form onSubmit={handleSubmit} style={{textAlign: "right"}}>
                <List sx={{ height: "75%"}}>
                    <ListItem key="header" alignItems="flex-start" sx={{ borderBottom: "1px solid #ddd" }}>
                        <Typography variant="h6" sx={{ marginRight: '60px'}}>
                            Import File Columns
                        </Typography>
                        <Typography variant="h6">Configuration Values</Typography>
                    </ListItem>
                    {configurations?.map((config) => (
                        <MappingListItem key={config.id} config={config} values={values} errors={errors} setFieldValue={setFieldValue} handleBlur={handleBlur} columnOptions={columnOptions}/>
                    ))}
                </List>
                <Button variant="contained" type="submit" disabled={hasErrors || isSubmitting}>Next</Button>
            </form>
        </Stack>
    );
};

const MappingListItem = ({ config, values, errors, setFieldValue, handleBlur, columnOptions }) => {
    const isValid = !!values[config.id];
    const hasError = !!errors[config.id];
    const value = values[config.id] || null;
    return (
        <ListItem
            key={config?.id + config?.name}
            sx={{ margin: '10px 0', paddingBottom: '15px', borderBottom: '1px solid #ddd', height: "60px", '& .MuiFormControl-root': { height: "100%"}  }}
        >
            <Autocomplete
                options={columnOptions}
                sx={{ minWidth: "200px", height: "60px" }}
                renderInput={(params) => <TextField
                    {...params}
                    placeholder="select an item"
                    error={hasError}
                    label={hasError ? 'required' : null}
                    height="50px"            
                />}
                name={config.id.toString()}
                value={value}
                onChange={(_, val) => setFieldValue(config.id.toString(), val)}
                onBlur={handleBlur}
            />
            <ArrowForwardIcon sx={{ margin: '0 10px' }} />
            {config.isRequired ? (
                <Alert
                    icon={false}
                    severity={isValid ? 'success' : 'error'}
                    sx={{ width: '100%', fontSize: '16px' }}
                >
                    {config.name}
                </Alert>
            ) : (
                <Typography variant="body2" sx={{ marginLeft: '15px', fontSize: '16px' }}>
                    {config.name}
                </Typography>
            )}
        </ListItem>
    );
};
