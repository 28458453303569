import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import configService from '../../services/ConfigService';
import { columnAliases } from '../ManageBeds/manageBeds';

export const validateConfig = createAsyncThunk(
    'validateConfig',
    handleApiCall((data) => configService.validateConfig(data)
    )
);

export const finalizeConfig = createAsyncThunk(
    'finalizeConfig',
    handleApiCall((data) => configService.finalizeConfig(data)
    )
);

const initialState = {
    isLoading: true,
    page: 1,
    totalPageCount: null,
    columns: null,
    configurations: null,
    fileReference: null,
    initialValues: null,
    validationResult: {
        wasSuccessful: null,
        errors: null,
        summary: null,
    }
};

const slice = createSlice({
    name: 'siteConfiguration',
    initialState,
    reducers: {
        setPage(state, action) {
            state.page = action.payload;
        },
        initializeMappingStep(state, action) {
            state.columns = action.payload.columns;
            state.configurations = action.payload.configurations;
            state.initialValues = action.payload.initialValues;
            state.fileReference = action.payload.fileReference;
        },
        setConfigToSave(state, action) {
            state.configToSave = action.payload;
        },
        reset(state) {
            // reset everything except the selected site
            return {...initialState, selectedSite: state.selectedSite}
        }
    },
    extraReducers: {
        [validateConfig.pending]: (state) => {
            state.isValidatingConfig = true;
        },
        [validateConfig.fulfilled]: (state, action) => {
            state.isValidatingConfig = false;
            state.validationResult = action.payload;
        },
        [validateConfig.rejected]: (state) => {
            state.isValidatingConfig = false;
        },
        [finalizeConfig.pending]: (state) => {
            state.isFinalizingConfig = true;
        },
        [finalizeConfig.fulfilled]: (state) => {
            state.isFinalizingConfig = false;
        },
        [finalizeConfig.rejected]: (state) => {
            state.isFinalizingConfig = false;
        }
    },
});

export const { setPage, setSelectedSite, initializeMappingStep, setConfigToSave, reset } = slice.actions;

const findConfigurationsSlice = { reducer: slice.reducer, initialState };
export default findConfigurationsSlice;

//Thunks

export const uploadConfig = (siteId, files) => async (dispatch) => {
    if (!siteId || !files?.length) return;

    try {
        const result = await configService.uploadConfig({ siteId }, files, (progress) => {}
        );
        const parsedResult = JSON.parse(result);

        // Attempt to automap columns
        const findMatchingColumn = (columnNameToMatch) => {
            const config = parsedResult.columns.find(({ label }) => {
                const trimmedLabel = label.toLowerCase().trim();
                const trimmedColumnNameToMatch = columnNameToMatch.toLowerCase().trim();
                const aliases = columnAliases[columnNameToMatch];
                return trimmedLabel === trimmedColumnNameToMatch || aliases?.includes(trimmedLabel);
            });
            return config ?? null;
        };

        let initialValues = {}

        parsedResult?.configurations.forEach((config) => {
            initialValues[config.id] = findMatchingColumn(config.name, config.isRequired);
        });

        dispatch(initializeMappingStep({ ...parsedResult, initialValues }));
        return result;
    } catch (e) {
        console.error('file Upload failed with error:', e);
        // TODO: use thunkAPI.dispatch(someaction()) to trigger a centralizederror popup
        return e;
    }
};
