import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import HospitalIcon from '@mui/icons-material/Business';
import ReportIcon from '@mui/icons-material/Report';
import { useSelector } from 'react-redux';

export const Review = ({ onStepComplete, onCancel }) => {
    const validationResult = useSelector((state) => state.manageBeds.validationResult);
    const isValidatingConfig = useSelector((state) => state.manageBeds.isValidatingConfig);
    const { wasSuccessful, errors, summary } = validationResult || {};

    return (
        <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: '75%', maxHeight: '100%', overflow: 'hidden' }}
        >
            {isValidatingConfig ? (
                <CircularProgress />
            ) : wasSuccessful ? (
                <ReviewConfiguration summary={summary} onStepComplete={onStepComplete} />
            ) : (
                <ReviewErrors errors={errors} onCancel={onCancel} />
            )}
        </Stack>
    );
};

const ReviewConfiguration = ({ summary, onStepComplete }) => {
    const siteName = useSelector((state) => state.sites.selectedSite.name);
    const configToSave = useSelector((state) => state.manageBeds.configToSave);
    const error = useSelector((state) => state.errors.error);
    const {
        bedsAvailableInSite,
        bedsOccupiedInSite,
        bedsToAvailable,
        bedsToOccupied,
        totalBedsInFile,
        totalBedsInSite,
    } = summary || {};

    const baseRowStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textAlign: 'left',
        padding: '5px 10px',
        marginBottom: 0,
        border: '1px solid #D6D6D6',
    };

    return (
        <Box
            textAlign="center"
            sx={{
                width: '100%',
                maxHeight: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'auto',
            }}
        >
            <Typography variant="h5" gutterBottom color="primary" flex="none">
                Review Bed Updates
            </Typography>
            <Typography variant="body1" gutterBottom flex="none">
                The following is a reconciliation summary comparison for:
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: 5,
                    flex: 'none',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    sx={{ backgroundColor: '#F2F2F2', padding: '5px 12px' }}
                >
                    <HospitalIcon sx={{ fontSize: '25px', marginRight: '10px' }} color="primary" />
                    <Typography variant="h6" gutterBottom color="primary" sx={{ margin: '0' }}>
                        {siteName}
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" style={{ width: '100%', margin: "10px" }}>
                    <Stack direction="column" sx={{width: "50%", marginRight: "10px"}}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, backgroundColor: '#F2F2F2' }}
                        >
                            Current Configuration
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles}}
                        >
                            Total Beds Configured: <span>{totalBedsInSite}</span>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, borderTop: 0 }}
                        >
                            Current Beds Available: <span>{bedsAvailableInSite}</span>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, borderTop: 0 }}
                        >
                            Current Beds Unavailable: <span>{bedsOccupiedInSite}</span>
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack direction="column" sx={{width: "50%", marginLeft: "10px"}}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, backgroundColor: '#F2F2F2' }}
                        >
                            Reconciliation Import
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, borderTop: 0 }}
                        >
                            Total Beds in File: <span>{totalBedsInFile}</span>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, borderTop: 0 }}
                        >
                            Beds to Set as Available: <span>{bedsToAvailable}</span>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ ...baseRowStyles, borderTop: 0 }}
                        >
                            Beds to Set as Unavailable: <span>{bedsToOccupied}</span>
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
            <Button
                variant="contained"
                sx={{ align: 'center', marginTop: '20px', flex: 'none', maxWidth: '400px' }}
                onClick={() => {
                    onStepComplete && onStepComplete(configToSave);
                }}
            >
                Submit Bed Updates
            </Button>
            {!!error && (
                <Alert sx={{ marginTop: '10px' }} severity="error">
                    <AlertTitle>Oops ... an error occured</AlertTitle>Please correct the file and
                    try the upload again
                </Alert>
            )}
        </Box>
    );
};

const ReviewErrors = ({ errors, onCancel }) => {
    if (!errors) return null;
    const errorCount = Object.keys(errors).length;
    return (
        <Box
            maxWidth="600px"
            textAlign="center"
            sx={{ maxHeight: '90%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ marginBottom: 2 }}
            >
                <ReportIcon sx={{ fontSize: '50px', marginRight: '10px' }} color="error" />
                <Typography variant="h6" gutterBottom>
                    Uh Oh, {errorCount} Errors Found!
                </Typography>
            </Stack>
            <Typography variant="body1" gutterBottom marginBottom={3} textAlign="center">
                You will need to fix these before you can successfully set up reconciliation.
                Reference the error summary below to correct your file and start over.
            </Typography>
            <TableContainer component={Paper} elevation={1}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Row</TableCell>
                            <TableCell align="left">Error</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(errors).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="left">Missing Value(s): {value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" onClick={onCancel} sx={{ marginTop: '20px' }}>
                Exit
            </Button>
        </Box>
    );
};
