import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import HospitalIcon from '@mui/icons-material/Business';
import ReportIcon from '@mui/icons-material/Report';
import { useSelector } from 'react-redux';

export const Review = ({ onStepComplete, onCancel }) => {
    const validationResult = useSelector((state) => state.siteConfiguration.validationResult);
    const isValidatingConfig = useSelector((state) => state.siteConfiguration.isValidatingConfig);
    const { wasSuccessful, errors, summary } = validationResult || {};

    return (
        <Stack direction="column" justifyContent="start" sx={{maxHeight: "100%", overflow: "hidden"}}>
            {isValidatingConfig ? (
                <CircularProgress />
            ) : wasSuccessful ? (
                <ReviewConfiguration
                    summary={summary}
                    onStepComplete={onStepComplete}
                />
            ) : (
                <ReviewErrors errors={errors} onCancel={onCancel}/>
            )}
        </Stack>
    );
};

const ReviewConfiguration = ({ summary, onStepComplete }) => {
    const configToSave = useSelector((state) => state.siteConfiguration.configToSave);
    const { siteName, facilities, totalBeds, totalFacilities, totalFloors, totalRooms } = summary;

    const countSummary = `${totalFacilities} facilities | ${totalFloors} floors | ${totalRooms} rooms | ${totalBeds} beds`;

    return (
        <Box textAlign="center" sx={{maxHeight: "90%", display: "flex", flexDirection: "column", overflow: "auto"}}>
            <Typography variant="h5" gutterBottom color="primary" flex="none">
                Review Configuration
            </Typography>
            <Typography variant="body1" gutterBottom flex="none">
                The following configuration will be setup for:
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 5,
                    flex: 'none',
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    sx={{ backgroundColor: '#F2F2F2', padding: '5px 12px' }}
                >
                    <HospitalIcon sx={{ fontSize: '50px', marginRight: '10px' }} color="primary" />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom color="primary">
                            {siteName}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {countSummary}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <TableContainer component={Paper} elevation={1} sx={{ flex: "1 1 0%"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Facility</TableCell>
                            <TableCell align="right">Floors</TableCell>
                            <TableCell align="right">Rooms</TableCell>
                            <TableCell align="right">Beds</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {facilities.map((row) => (
                            <TableRow key={row.facilityName}>
                                <TableCell component="th" scope="row">
                                    {row.facilityName}
                                </TableCell>
                                <TableCell align="right">{row.floorsCount}</TableCell>
                                <TableCell align="right">{row.roomsCount}</TableCell>
                                <TableCell align="right">{row.bedsCount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                sx={{ marginTop: '20px', flex: 'none' }}
                onClick={() => {
                    onStepComplete && onStepComplete(configToSave);
                }}
            >
                Submit Configuration
            </Button>
        </Box>
    );
};

const ReviewErrors = ({ errors, onCancel }) => {
  if(!errors) return null;
    const errorCount = Object.keys(errors).length;
    return (
        <Box maxWidth="600px" textAlign="center" sx={{maxHeight: "90%", display: "flex", flexDirection: "column", overflow: "auto"}}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ marginBottom: 2 }}
            >
                <ReportIcon sx={{ fontSize: '50px', marginRight: '10px' }} color="error" />
                <Typography variant="h6" gutterBottom>
                    Uh Oh, {errorCount} Errors Found!
                </Typography>
            </Stack>
            <Typography variant="body1" gutterBottom marginBottom={3} textAlign="center">
                You will need to fix these before you can successfully set up configuration.
                Reference the error summary below to correct your file and start over.
            </Typography>
            <TableContainer component={Paper} elevation={1}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Row</TableCell>
                            <TableCell align="left">Error</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(errors).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="left">Missing Value(s): {value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" onClick={onCancel} sx={{ marginTop: '20px' }}>
                Exit
            </Button>
        </Box>
    );
};
