import { useEffect, useRef } from "react"
import { useAuth } from "./useAuth";
import { setStoredRedirectUrl } from './localStorage/redirectUrl';
import { useEnvironment } from "../environment";

/**
 * Hook to encapsulate logic to redirect to the login page
 * when the user is unauthenticated.
 */
export const useRedirectToLogin = () => {
    const { isAuthenticated, isInProgress, login } = useAuth();
    const { PORTAL_NAME } = useEnvironment();

    // this prevents login from being called multiple times
    // before isInProgress updates to true
    const wasLoginTriggeredRef = useRef(false);

    useEffect(() => {
        if (isAuthenticated || isInProgress || wasLoginTriggeredRef.current) 
            return;

        // store return URL for when returning to app
        const returnUrl = window.location.pathname.replace('/' + PORTAL_NAME, '');
        setStoredRedirectUrl(returnUrl);

        // trigger login
        wasLoginTriggeredRef.current = true;
        login();
    }, [isAuthenticated, isInProgress, login, PORTAL_NAME]);
}