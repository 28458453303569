import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, styled } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from "@mui/material/Toolbar";
import { useAuth } from '../features/auth';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ImageWithFallback } from '../components/ImageWithFallback/ImageWithFallback';
import MhaLogo from '../assets/MHA-Logo.webp';
import fallBackLogo from '../assets/MHA-Logo.png';
import './Navbar.scss';

function a11yProps(index) {
    return {
      id: `navigation-tab-${index}`,
      'aria-controls': `navigation-tabpanel-${index}`,
    };
}

const secretRoutes = ['juvare', 'logs'];

// See https://stackoverflow.com/a/48934864/241392
// This applies the responsive height of the toolbar to
// the tab component, allowing it to stretch the full height.
const ResponsiveTab = styled(Tab)(({ theme }) =>  ({
    ...theme.mixins.toolbar,
}));

const ResponsiveTabs = styled(Tabs)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

export const Navbar = () => {
    const { isAuthenticated, logout, login } = useAuth();
    const siteId = useSelector((state) => state.sites?.selectedSite?.siteId)
    const [searchParams] = useSearchParams();
    const logoutMessage = searchParams.get('logoutMessage');

    // This is a hack to get the current tab value to show up correctly, previously with MUI's suggested route, 
    // the active tab would not show up correctly on page refresh, or in other dynamic nav scenarios. 
    const location = useLocation();
    let currentTab = isSecretRoute(location.pathname) ? false : stripNonLetterCharacters(location.pathname) || 'find';

    return (
        <AppBar 
            className="nav-bar"
            color="transparent" 
            elevation={1}
            position="relative" 
        >
            <Toolbar
                sx={{ borderTop: "solid 3px #0072c6" }}
            >
                <ImageWithFallback className="nav-bar__logo" src={MhaLogo} fallback={fallBackLogo} alt="branding-logo"/>
                <Box display="flex" flexGrow={1} height="100%">
                    <ResponsiveTabs
                        aria-label="navigation"
                        value={currentTab}
                    >
                        <ResponsiveTab
                            LinkComponent={Link}
                            label="Find Beds"
                            to="/find"
                            value="find"
                            {...a11yProps(0)}
                        />
                        <ResponsiveTab
                            LinkComponent={Link}
                            label="Manage Beds"
                            to={`/manage/${siteId || ''}`}
                            value="manage"
                            {...a11yProps(1)}

                        />
                        <ResponsiveTab
                            LinkComponent={Link}
                            label="Site Configuration"
                            to={`/config/${siteId || ''}`}
                            value="config"
                            {...a11yProps(2)}
                        />
                    </ResponsiveTabs>
                </Box>
                <Box>
                    {logoutMessage && (<Box sx={{ display: 'inline-flex'}}><Alert severity="warning" sx={{ marginRight: '10px' }}>Your session has timed out. Please re-authenticate to access admin features</Alert><Button onClick={() => login()}>Log back in</Button></Box>)}
                    {isAuthenticated && (
                        <Button onClick={() => logout()}>
                            Logout
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

function stripNonLetterCharacters(inputString) {
    // Use a regular expression to match any non-letter character (A-Z or a-z)
    const regex = /[^A-Za-z]/g;
    
    // Use the replace method to replace all matched characters with an empty string
    return inputString.replace(regex, '');
  }

function isSecretRoute(string) {
    return secretRoutes.some(route => string.includes(route));
}
  