import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import bedService from '../../services/BedService';

export const validateReconciliation = createAsyncThunk(
    'validateReconciliation',
    handleApiCall((data) => bedService.validateReconciliation(data)
    )
);

export const finalizeReconciliation = createAsyncThunk(
    'finalizeReconciliation',
    handleApiCall((data) => bedService.finalizeReconciliation(data)
    )
);

const initialState = {
    selectedSite: {
        isConfigured: null,
        siteId: null,
        name: null,
    },
    sites: [],
    isLoading: true,
    page: 1,
    totalPageCount: null,
    columns: null,
    configurations: null,
    configToSave: {
        siteId: null,
        fileReference: null,
        config: null,
        importSettings: null,
    },
    fileReference: null,
    initialValues: null,
    validationResult: {
        wasSuccessful: null,
        errors: null,
        summary: null,
    },
    error: null,
};

const slice = createSlice({
    name: 'manageBeds',
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setSites(state, action) {
            state.sites = action.payload;
        },
        setSelectedSite(state, action) {
            state.selectedSite = action.payload;
        },
        initializeMappingStep(state, action) {
            state.columns = action.payload.columns;
            state.configurations = action.payload.configurations;
            state.initialValues = action.payload.initialValues;
            state.fileReference = action.payload.fileReference;
        },
        setConfigToSave(state, action) {
            state.configToSave = action.payload;
        },
        reset(state) {
            // reset everything except the selected site
            return {...initialState, selectedSite: state.selectedSite}
        }
    },
    extraReducers: {
        [validateReconciliation.pending]: (state) => {
            state.isValidatingConfig = true;
        },
        [validateReconciliation.fulfilled]: (state, action) => {
            state.isValidatingConfig = false;
            state.validationResult = action.payload;
        },
        [validateReconciliation.rejected]: (state) => {
            state.isValidatingConfig = false;
        },
        [finalizeReconciliation.pending]: (state) => {
            state.isFinalizingConfig = true;
        },
        [finalizeReconciliation.fulfilled]: (state) => {
            state.isFinalizingConfig = false;
        },
        [finalizeReconciliation.rejected]: (state) => {
            state.isFinalizingConfig = false;
        }
    },
});

export const { setError, setPage, setSelectedSite, initializeMappingStep, setConfigToSave, reset } = slice.actions;

const findConfigurationsSlice = { reducer: slice.reducer, initialState };
export default findConfigurationsSlice;

//Thunks

export const uploadReconciliation = (siteId, files) => async (dispatch) => {
    if (!siteId || !files?.length) return;
    dispatch(setError(null));
    try {
        const result = await bedService.uploadReconciliation({ siteId }, files, (progress) => {}
        );
        const parsedResult = JSON.parse(result);

        //Attempt to automap columns
        const findMatchingColumn = (columnNameToMatch) => {
            const config = parsedResult.columns.find(({ label }) => {
                const trimmedLabel = label.toLowerCase().trim();
                const trimmedColumnNameToMatch = columnNameToMatch.toLowerCase().trim();
                const aliases = columnAliases[columnNameToMatch];
                return trimmedLabel === trimmedColumnNameToMatch || aliases?.includes(trimmedLabel);
            });
            return config ?? null;
        };

        let initialValues = {}

        parsedResult?.configurations.forEach((config) => {
            initialValues[config.id] = findMatchingColumn(config.name);
        });

        await dispatch(initializeMappingStep({ ...parsedResult, initialValues }));
        return parsedResult;
    } catch (e) {
        console.error('file Upload failed with error:', e);

        const error = JSON.parse(e);
        const message = error.detail ?? error.message ?? e;
        dispatch(setError(message));
        // TODO: use thunkAPI.dispatch(someaction()) to trigger a centralizederror popup
        return { wasSuccessful: false, error: message }
    }
};

export const columnAliases = {
    'Bed Code': ['code', 'bed code', 'bed unique code'],
    'Bed Unique Code': ['unique code', 'bed unique code', 'bed code', 'code'],
    'Bed Service Status': ['status', 'bed status', 'bed service status'],
    'Bed Availability': ['availability', 'bed availability'],
};
