import DownloadIcon from '@mui/icons-material/FileDownload';
import SyncIcon from '@mui/icons-material/Sync';
import { ClientsAutocomplete } from '../../components/ClientsAutocomplete';
import { DateRangePicker } from './LogsDatePicker';
import { Stack, Button } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { findBedLogs } from './redux';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const LogsFilters = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [client, setClient] = useState(null);

    const siteId = searchParams.get('siteId');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    const updateParams = (values) => {
        setSearchParams({ ...FilterInvalidValues(values) });
    };

    const handleChange = (newClient) => {
        setClient(newClient);
        updateParams({ siteId: newClient?.siteId, startDate, endDate });
    };

    const handleDownload = () => {
        window.open(
            window?.environment?.API_URL +
                '/api/v1/beds/activities/download?' +
                searchParams.toString(),
            '_blank',
        );
    };

    const handleRender = () => {
        dispatch(
            findBedLogs({
                siteId,
                startDate,
                endDate,
            }),
        );
    };

    const isValid = () => {
        if (!siteId) return true;
        if (!(startDate || endDate)) return false;

        const start = new Date(startDate);
        const end = new Date(endDate);

        if (isNaN(start.getTime()) || isNaN(end.getTime())) return true;

        return differenceInDays(end, start) > 14;
    };

    return (
        <Stack direction="row" spacing={2}>
            <ClientsAutocomplete onChange={handleChange} value={client} initialClientId={siteId} />

            <DateRangePicker
                value={{
                    startDate,
                    endDate,
                }}
                onChange={(values) => {
                    updateParams({ siteId, ...values });
                }}
            />

            <Button
                variant="contained"
                startIcon={<SyncIcon />}
                onClick={handleRender}
                disabled={isValid()}
            >
                Show Results For Specified Range
            </Button>

            <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                disabled={isValid()}
            >
                Download Logs
            </Button>
        </Stack>
    );
};

// Helpers
const FilterInvalidValues = (values) => {
    const filteredValues = Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        if (value !== undefined && value !== null && value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {});

    return filteredValues;
};
