import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Button,
    Typography,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { BedImportSettings } from '../../../enums/BedImportSettings';
import { setConfigToSave } from '../manageBeds';

export const ImportSettings = ({ onStepComplete }) => {
    const dispatch = useDispatch();
    const configToSave = useSelector((state) => state.manageBeds?.configToSave);

    const { values = {}, errors, isSubmitting, handleSubmit, setFieldValue } = useFormik({
        initialValues: configToSave,
        validationSchema: Yup.object().shape({
            importSettings: Yup.number().required('Import settings are required'),
        }),
        onSubmit: ({importSettings}) => {            
            const data = {
               ...configToSave,
               importSettings
            };

            dispatch(setConfigToSave(data));
            onStepComplete && onStepComplete(data);
        },
    });

    const hasErrors = errors && Object.keys(errors).length > 0;
    const validationErrors = Object.values(errors);

    return (
        <Stack>
            <form onSubmit={handleSubmit} style={{ textAlign: 'right' }}>
                <Box
                    textAlign="center"
                    sx={{
                        maxHeight: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    <Typography variant="h5" gutterBottom color="primary" flex="none">
                        Specify Import Settings
                    </Typography>
                    <Typography variant="body1" gutterBottom flex="none">
                        How would you like to reconcile bed availability for beds not included in
                        the import file?*
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: 5,
                            flex: 'none',
                        }}
                    >
                        <FormControl>
                            <RadioGroup
                                name="importSettings"
                                onChange={(e) => setFieldValue('importSettings', e.target.value)}
                                defaultValue={null}
                                value={values.importSettings}
                            >
                                <FormControlLabel
                                    value={BedImportSettings.DoNotUpdate}
                                    control={<Radio />}
                                    label={BedImportSettings.getDescription(BedImportSettings.DoNotUpdate)}
                                />
                                <FormControlLabel value={BedImportSettings.SetToAvailable} control={<Radio />} label={BedImportSettings.getDescription(BedImportSettings.SetToAvailable)} />
                                <FormControlLabel value={BedImportSettings.SetToOccupied} control={<Radio />} label={BedImportSettings.getDescription(BedImportSettings.SetToOccupied)} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                {!!hasErrors && (
                    <Alert severity="error">
                        {/*currently there can only be one validation error */}
                        {validationErrors[0]}
                    </Alert>
                )}
                <Button style={{
                    marginTop: '20px',
                }}
                variant="contained" type="submit" disabled={hasErrors || isSubmitting}>
                    Next
                </Button>
            </form>
        </Stack>
    );
};
