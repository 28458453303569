import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack';

const SlimLayout = ({ children, title }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Stack height="100vh">    
                {children}
            </Stack>
        </>
    );
};

export default SlimLayout;