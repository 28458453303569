import React, { useState, forwardRef } from 'react';
import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Stepper,
    Step,
    StepLabel,
    Container,
    Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UploadFile } from './1-UploadFile';
import { MapColumns } from './2-MapColumns';
import { ImportSettings } from './3-ImportSettings';
import { Review } from './4-Review';
import { Confirmation } from './5-Confirmation';
import { finalizeReconciliation, reset, validateReconciliation } from '../manageBeds';
import { reset as resetErrors } from '../../../redux/errors';
import { useDispatch, useSelector } from 'react-redux';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ManageBedsWizard = ({ isVisible, onCancel }) => {
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const selectedSite = useSelector((state) => state.sites?.selectedSite);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleCancel = () => {
        setActiveStep(0);
        dispatch(reset());
        dispatch(resetErrors());
        onCancel && onCancel();
    };

    const stepStyles = {
        height: '100%',
        width: '800px',
        position: 'inherit',
    };

    const stepLabelStyles = {
        '& .MuiStepIcon-root.Mui-completed': {
            color: '#00c264',
        },
    };

    const steps = [
        <UploadFile onStepComplete={handleNext} />,
        <MapColumns
            onStepComplete={handleNext}
        />,
        <ImportSettings 
            onStepComplete={(data) => {
                dispatch(validateReconciliation(data));
                handleNext();
            }}
        />,
        <Review
            onStepComplete={async (data) => {
                const { payload } = await dispatch(finalizeReconciliation(data));
                if (!payload?.wasSuccessful) return;
                handleNext();
            }}
            onCancel={handleCancel}
        />,
        <Confirmation onComplete={handleCancel} />,
    ];

    return (
        <Dialog
            fullScreen
            open={isVisible}
            onClose={handleCancel}
            TransitionComponent={Transition}
            sx={{ maxHeight: '100vh', '& ,MuiDialog-paper': { overflow: 'hidden' } }}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCancel}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Manage Beds - {selectedSite?.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Stack
                direction="column"
                sx={{ width: '100%', height: "90%", padding: '25px' }}
            >
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{
                        height: '100px',
                        position: 'relative',
                    }}
                >
                    <Step key="Upload File" sx={stepStyles}>
                        <StepLabel sx={stepLabelStyles}>Upload File</StepLabel>
                    </Step>
                    <Step key="Map Columns" sx={stepStyles}>
                        <StepLabel sx={stepLabelStyles}>Map Columns</StepLabel>
                    </Step>
                    <Step key="Import Settings" sx={stepStyles}>
                        <StepLabel sx={stepLabelStyles}>Import Settings</StepLabel>
                    </Step>
                    <Step key="Review" sx={stepStyles}>
                        <StepLabel sx={stepLabelStyles}>Review</StepLabel>
                    </Step>
                    <Step key="Confirmation" sx={stepStyles}>
                        <StepLabel sx={stepLabelStyles}>Confirmation</StepLabel>
                    </Step>
                </Stepper>
                <Container
                    maxWidth="md"
                    sx={{
                        height: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                    }}
                >
                    {steps[activeStep]}
                </Container>
            </Stack>
        </Dialog>
    );
};
