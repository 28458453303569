import { setError } from "../redux/errors";
export const handleApiCall = (apiCall) => async (arg, thunkAPI) => {
    try {
        const result = await apiCall(arg, thunkAPI);

        // TODO: handle a successful http status, but failed validation
        // TODO: if result has failed validation, use thunkAPI.dispatch(someaction()) to trigger a centralized error popup

        return thunkAPI.fulfillWithValue(result);
    } catch (e) {
        console.error("handleApiCall failed with error:", e);
        // TODO: use this general error to trigger a centralized error popup
        thunkAPI.dispatch(setError(e));
        return thunkAPI.rejectWithValue(e);
    }
}