import {
    Box,
    Divider,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullHeightBox, FullHeightStack } from '../../components/FullHeightStack/FullHeightStack';
import { Suspend } from '../../components/Suspend';
import { findBeds, setPage } from './findBeds';
import { FindBedsFilters } from './FindBedsFilters';
import { useSearchParams } from 'react-router-dom';
import { useIsInitialRender } from '../../hooks/useIsInitialRender';

const FindBedsPage = () => {
    const dispatch = useDispatch();
    const { isLoading, beds, page, totalPageCount } = useSelector((state) => state.findBeds);
    const isInitialRender = useIsInitialRender();
    const [searchParams] = useSearchParams();
    const siteId = searchParams.get('siteId');
    const status = searchParams.get('status');
    const type = searchParams.get('type');

    useEffect(() => {
        // these params are sync'd within the filters component
        if (!status || !type || !!isInitialRender) return;
        dispatch(findBeds({ siteId, status, type, page: 1 }));
    }, [siteId, status, type, isInitialRender, dispatch]);

    const handlePageChange = async (_, value) => {
        await dispatch(findBeds({ siteId, status, type, page: value }));
        await dispatch(setPage(value));
    };

    return (
        <FullHeightStack spacing={2}>
            <Box spacing={12}>
                <FindBedsFilters />
            </Box>
            <Suspend isLoading={isLoading}>
                <FullHeightBox>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Client/Site</TableCell>
                                    <TableCell>Facility</TableCell>
                                    <TableCell>Floor</TableCell>
                                    <TableCell>Room</TableCell>
                                    <TableCell>Bed</TableCell>
                                    <TableCell>Bed Type</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {beds?.map((bed) => (
                                    <BedRow key={bed?.bedId} bed={bed} />
                                ))}
                                {beds?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                                            No beds were found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </FullHeightBox>
                <Box>
                    <Divider sx={{ marginBottom: '10px' }} />
                    <Pagination
                        count={totalPageCount ?? 0}
                        page={page}
                        onChange={handlePageChange}
                    />  
                </Box>
            </Suspend>
        </FullHeightStack>
    );
};

const BedRow = ({ bed }) => {
    return (
        <TableRow key={bed.bedId}>
            <TableCell>{bed.siteName}</TableCell>
            <TableCell>{bed.facilityName}</TableCell>
            <TableCell>{bed.floorName}</TableCell>
            <TableCell>{bed.roomName}</TableCell>
            <TableCell>{bed.bedName}</TableCell>
            <TableCell>{bed.bedTypeName}</TableCell>
            <TableCell>
                <Typography
                    sx={{
                        fontSize: 'body2.fontSize',
                        fontStyle: bed?.bedStatusName === 'Out of Service' ? 'italic' : 'normal',
                    }}
                    color={bed?.bedStatusName === 'Available' ? null : 'gray'}
                >
                    {bed?.bedStatusName}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default FindBedsPage;
