import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack';
import { Navbar } from './Navbar';
import { Content } from './Content';

const MainLayout = ({ children, title }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Stack height="100vh">    
                <Navbar />
                <Content>
                    {children}
                </Content> 
            </Stack>
        </>
    );
};

export default MainLayout;