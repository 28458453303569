import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';
import HospitalIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import { SiteConfigurationWizard } from './SiteConfigurationWizard';

export const SiteConfigRightPane = () => {
    const { siteId } = useParams();
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const selectedSite = useSelector((state) => state.sites?.selectedSite);
    const sites = useSelector((state) => state.sites?.sites);

    const { isConfigured } = selectedSite;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Stack direction="column" height="300px" alignItems="center">
                {!!sites?.length && isConfigured && (
                    <>
                        <CheckIcon sx={{ fontSize: '75px', color: "#00c264" }} />
                        <Typography variant="h6" gutterBottom>
                            This site is already configured
                        </Typography>
                        <Button
                            aria-label="View Configuration"
                            color="primary"
                            onClick={() => navigate(`/find?siteId=${siteId}`)}
                            variant="contained"
                        >
                            View Configuration
                        </Button>
                    </>
                )}
                {!!sites?.length && !isConfigured && (
                    <>
                        <HospitalIcon sx={{ fontSize: '50px' }} color="primary" />
                        <Typography variant="body1" sx={{ margin: '10px' }}>
                            You do not have this site configured
                        </Typography>
                        <Button
                            aria-label="Upload Configuration"
                            color="primary"
                            onClick={() => setShowDialog(true)}
                            variant="contained"
                            startIcon={<UploadIcon />}
                        >
                            Start Configuration
                        </Button>
                    </>
                )}
                {!sites?.length && (
                    <>
                        <HospitalIcon sx={{ fontSize: '50px' }} color="primary" />
                        <Typography variant="h6" sx={{ margin: '10px' }}>
                            There are no sites configured yet. Please add a site first.
                        </Typography>
                    </>
                )}
            </Stack>
            <SiteConfigurationWizard isVisible={showDialog} onCancel={() => setShowDialog(false)} />
        </Box>
    );
};
