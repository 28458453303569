import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';
import HospitalIcon from '@mui/icons-material/Business';
import BedIcon from '@mui/icons-material/Hotel';

import { ManageBedsWizard } from './ManageBedsWizard';

export const ManageBedsRightPane = () => {
    const [showDialog, setShowDialog] = useState(false);
    const selectedSite = useSelector((state) => state.sites?.selectedSite);
    const sites = useSelector((state) => state.sites?.sites);
    const { name } = selectedSite;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Stack direction="column" height="300px" alignItems="center">
                {!!sites?.length && name && (
                    <><Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HospitalIcon sx={{ fontSize: '50px' }} color="primary" />
                        <BedIcon sx={{ fontSize: '50px' }} color="primary" />
                    </Box>
                        <Typography variant="h6" color="primary" sx={{ margin: '10px' }}>
                            {name}
                        </Typography>
                        <Button
                            aria-label="Upload Configuration"
                            color="primary"
                            onClick={() => setShowDialog(true)}
                            variant="contained"
                            startIcon={<UploadIcon />}
                        >
                            Manage Bed Availability
                        </Button>
                    </>
                )}
                {!!sites?.length && !name && (
                    <>
                        <HospitalIcon sx={{ fontSize: '50px' }} color="primary" />
                        <Typography variant="h6" sx={{ margin: '10px' }}>
                            Please select a site on the left
                        </Typography>
                    </>
                )}
                {!sites?.length && (
                    <>
                        <HospitalIcon sx={{ fontSize: '50px' }} color="primary" />
                        <Typography variant="h6" sx={{ margin: '10px' }}>
                            There are no sites configured yet. Please add a site first.
                        </Typography>
                    </>
                )}
            </Stack>
            <ManageBedsWizard isVisible={showDialog} onCancel={() => setShowDialog(false)} />
        </Box>
    );
};
