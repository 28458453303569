import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { findClients } from '../../pages/FindBeds/findBeds';
import { Autocomplete, TextField } from '@mui/material';
import isEqual from 'lodash/isEqual';


export const ClientsAutocomplete = ({ onChange, value, initialClientId }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const isFetchingClients = useRef(false);
    const hasFetchedInitialClient = useRef(false);

    useEffect(() => {
        if(isFetchingClients?.current) return;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return;
        }

        const doFetch = async () => {
            isFetchingClients.current = true;

            const results = await dispatch(findClients({ name: inputValue, size: 20 }));
            const items = results.payload?.items?.map(({ key, label }) => ({
                siteId: key,
                displayName: label,
            }));

            let newOptions = [];
            if (value) {
                newOptions = [value];
            }
            if (items) {
                newOptions = [...newOptions, ...items];
            }
            setOptions(newOptions);

            isFetchingClients.current = false;
        };

        doFetch();
    }, [value, inputValue, dispatch]);

    useEffect(() => {
        if(!!isFetchingClients?.current || !!hasFetchedInitialClient?.current || !initialClientId ) return;

        const fetchInitialClient = async () => {
            isFetchingClients.current = true;

            const result = await Promise.resolve(dispatch(findClients({ siteId: initialClientId, size: 1 })));
            const items = result.payload?.items?.map(({ key, label }) => ({
                siteId: key,
                displayName: label,
            }));

            setOptions(items);
            onChange(items[0]);

            hasFetchedInitialClient.current = true;
            isFetchingClients.current = false;
        };

        fetchInitialClient();
    }, [hasFetchedInitialClient, initialClientId, dispatch, onChange]);

    const onOpen = async () => {
        setInputValue('');

        const results = await dispatch(findClients({ size: 20 }));
        const items = results.payload?.items?.map(({ key, label }) => ({
            siteId: key,
            displayName: label,
        }));

        if(!isEqual(items, options)) setOptions(items);
    };

    return (
        <Autocomplete
            size="small"
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.displayName}
            filterOptions={(x) => x}
            options={options}
            onOpen={onOpen}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(_, newValue) => {
                if(!isEqual(newValue, value)) {
                    setOptions(newValue ? [newValue, ...options] : options);
                    onChange(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                if (event?.type === 'change') {
                    setInputValue(newInputValue);
                }
            }}
            renderInput={(params) => <TextField {...params} label="Select Client/Site" fullWidth />}
            renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
            isOptionEqualToValue={(option, value) => option?.siteId === value?.siteId}
        />
    );
};
