import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
};

const slice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },
        reset(state) {
            // reset everything except the selected site
            return {...initialState }
        }
    },
});

export const { setError, reset } = slice.actions;

const errorSlice = { reducer: slice.reducer, initialState };
export default errorSlice;
