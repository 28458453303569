import Box from '@mui/material/Box';
import React from 'react';

export const Content = ({ children }) => {
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            flexGrow={1}
            overflow="hidden"
            p={3} 
        >
            {children}
        </Box>
    );
};