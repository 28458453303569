import React from 'react';
import { List, ListItem, ListItemButton, Typography, Box } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadSites, setSelectedSite } from './redux';
import { LoadingOverlay } from '../LoadingOverlay';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';

export const SitesSidebar = ({shouldOnlyShowConfiguredSites = false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sites = useSelector((state) => state.sites?.sites);
    const numSites = sites?.length || 0;
    const filteredSites = shouldOnlyShowConfiguredSites ? sites?.filter((site) => site?.isConfigured) : sites;
    
    const isLoading = useSelector((state) => state.sites?.isLoading);
    const selectedSite = useSelector((state) => state.sites?.selectedSite);

    useDeepCompareEffect(() => {
        if (!numSites) dispatch(loadSites());
    }, [numSites, dispatch]);

    useDeepCompareEffect(() => {
        if (filteredSites?.length && !selectedSite?.siteId) {
            dispatch(setSelectedSite(filteredSites[0]));
            navigate(`./${filteredSites[0]?.siteId}`);
            return;
        }

        if (selectedSite?.siteId) {
            const site = filteredSites?.find((site) => site.siteId === selectedSite.siteId);
            if (site) {
                dispatch(setSelectedSite(site));
                navigate(`./${selectedSite?.siteId}`);
                return;
            }
        }

        return () => {
            //clear selected site on unmount
            dispatch(setSelectedSite({}));
        }
    }, [filteredSites, selectedSite, dispatch, navigate]);

    return (
        <div>
            <Box variant="h6" sx={{ display: "flex", alignItems: "center", marginBottom: '20px', color: '#1976d2' }}>
                <BusinessIcon fontSize="large" /><Typography variant="h6" sx={{ marginLeft: '5px' }}>Sites</Typography>
            </Box>
            {isLoading ? (
                <LoadingOverlay />
            ) : (
                <List sx={{ color: "#1976d2" }}>
                    {!!filteredSites?.length && filteredSites.map((site) => (
                        <ListItem disablePadding key={site.siteId}>
                            <ListItemButton selected={site.siteId === selectedSite?.siteId} component={Link} to={`./${site?.siteId}`} onClick={() => dispatch(setSelectedSite(site))}>{site.name}</ListItemButton>
                        </ListItem>
                    ))}
                    {
                        !filteredSites?.length && <ListItem disablePadding key={"no-sites-found"}>
                            <Typography variant="body1" sx={{ margin: '10px' }}>No sites found</Typography>
                        </ListItem>
                    }
                </List>
            )}
        </div>
    );
};