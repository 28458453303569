import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import findBeds from '../pages/FindBeds/findBeds';
import siteConfiguration from '../pages/SiteConfiguration/siteConfiguration';
import sites from '../components/SitesSidebar/redux';
import manageBeds from '../pages/ManageBeds/manageBeds';
import juvare from '../pages/Juvare/redux';
import logs from '../pages/Logs/redux';
import errors from './errors';

const slices = {
    errors,
    findBeds,
    sites,
    siteConfiguration,
    manageBeds,
    juvare,
    logs,
};

const reducers = Object.keys(slices).reduce((acc, name) => {
    acc[name] = slices[name].reducer;
    return acc;
}, {});

const rootReducer = combineReducers(reducers);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});