import { Typography, Stack, Button, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Confirmation = () => {
    const navigate = useNavigate();
    const { siteId } = useParams();
    const isFinalizingConfig = useSelector((state) => state.siteConfiguration.isFinalizingConfig);

    return (
        <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
            {isFinalizingConfig ? (
                <CircularProgress />
            ) : (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    maxWidth="500px"
                >
                    <CheckIcon sx={{ fontSize: '75px', color: '#57d25d' }} />
                    <Typography variant="h6" gutterBottom>
                        Success!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Reconciliation has completed successfully, you can now view your site by
                        clicking the button below:
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ marginTop: 2 }}
                        onClick={() => navigate(`/find?type=0&status=0&siteId=${siteId}`)}
                    >
                        View Bed Availability
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};
