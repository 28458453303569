import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { AuthProvider } from './features/auth';
import { EnvironmentProvider } from './features/environment';
import { MuiThemeProvider } from './features/mui/MuiThemeProvider';
import { store } from './redux/store';

const AppRootComponents = ({ children }) => {
    return (
        <EnvironmentProvider>
            <Provider store={store}>
                <AuthProvider>
                    <HelmetProvider>
                        <MuiThemeProvider>
                            {children}
                        </MuiThemeProvider>
                    </HelmetProvider>
                </AuthProvider>
            </Provider>
        </EnvironmentProvider>
    );
};

export default AppRootComponents;