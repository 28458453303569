import { useRef, useEffect } from 'react';

/**
 * Hook that will return true if it is the initial render,
 * otherwise, false.
 */
export function useIsInitialRender() {
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        }
    });

    return isInitialRender.current;
}