import './SiteConfigurationPage.scss'
import React from 'react';
import SidebarLayout from '../../layouts/SidebarLayout';
import { Routes, Route } from 'react-router-dom';
import { SiteConfigRightPane } from './components/SiteConfigRightPane';
import { SitesSidebar } from '../../components/SitesSidebar/SitesSidebar';

const SiteConfigurationPage = () => {
    return (
        <SidebarLayout 
            sideBar={SitesSidebar}
            content={Content}
        />
    );
};

const Content = () => {
    return (
        <Routes>
            <Route index element={<SiteConfigRightPane />} />
            <Route path="/:siteId" element={<SiteConfigRightPane />} />
        </Routes>
    );
};

export default SiteConfigurationPage;