import { CircularProgress, Stack, Typography } from "@mui/material";

export const Suspend = ({
    children,
    fallback = <DefaultLoadingFallback />,
    isLoading,
}) => {
    return isLoading ? fallback : children;
};

const DefaultLoadingFallback = () => (
    <Stack 
        alignItems="center" 
        height="100%"
        justifyContent="center" 
        spacing={2}
        width="100%" 
    >
        <Typography>Loading...</Typography>
        <CircularProgress />
    </Stack>
);