import * as React from 'react';
import Box from '@mui/material/Box';
import { Stack, Divider } from '@mui/material';

const sideBarWidth = 300;

const SidebarLayout = ({ sideBar, content, ...props }) => {
    return (
        <Stack height="100%" direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2} sx={{
            padding: '0',
        }}>
            <Box
                sx={{
                    width: sideBarWidth,
                    height: "100%",
                }}
            >
                {sideBar({ ...props })}
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                {content(props)}
            </Box>
        </Stack>
    );
};

export default SidebarLayout;
