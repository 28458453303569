import {
    Alert,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullHeightBox, FullHeightStack } from '../../components/FullHeightStack/FullHeightStack';
import { Suspend } from '../../components/Suspend';
import { findJuvareBeds, setItems } from './redux';
import { useSearchParams } from 'react-router-dom';
import { useIsInitialRender } from '../../hooks/useIsInitialRender';
import { JuvareFilters } from './JuvareFilters';

const toggleCellStyles = {
    width: '25px',
    padding: '0',
};

const JuvarePage = () => {
    const dispatch = useDispatch();
    const { isLoading, items } = useSelector((state) => state.juvare);
    const isInitialRender = useIsInitialRender();
    const [searchParams] = useSearchParams();
    const siteId = searchParams.get('siteId');

    useEffect(() => {
        if (isInitialRender) return;
        if (siteId) {
            dispatch(findJuvareBeds({ siteId }));
        } else {
            dispatch(setItems([]));
        }
    }, [siteId, isInitialRender, dispatch]);

    return (
        <FullHeightStack spacing={2}>
            <Box spacing={12}>
                <JuvareFilters />
            </Box>
            <Suspend isLoading={isLoading && siteId}>
                <FullHeightBox>
                    {!isLoading && !!siteId && (
                        <TableContainer component={Paper}>
                            <Table size="small" sx={{ borderCollapse: 'separate' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ paddingLeft: '12px' }}>
                                            Juvare Status Name
                                        </TableCell>
                                        <TableCell>Juvare Mapped Element Name</TableCell>
                                        <TableCell>Current Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items?.map((item) => (
                                        <ItemRow key={item?.statusName} item={item} />
                                    ))}
                                    {items?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                                                No results were found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {!siteId && (
                        <Alert severity="info">Please select a site to view Juvare status</Alert>
                    )}
                </FullHeightBox>
            </Suspend>
        </FullHeightStack>
    );
};

const ItemRow = ({ item }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const hasBeds = !!item?.beds?.length;
    const parentCellStyle = hasBeds
        ? {
              borderTop: '1px solid black',
              borderBottom: '1px solid transparent',
              fontWeight: 'bold',
              cursor: 'pointer',
          }
        : {};

    return (
        <>
            <TableRow
                key={item.bedId}
                sx={parentCellStyle}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <TableCell size="small" sx={{ ...toggleCellStyles, ...parentCellStyle }}>
                    {hasBeds && (
                        <IconButton aria-label="expand row" size="small">
                            {isCollapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell sx={parentCellStyle}>{item.statusName}</TableCell>
                <TableCell sx={parentCellStyle}>{item.statusFriendlyName}</TableCell>
                <TableCell sx={parentCellStyle}>{item.statusValue}</TableCell>
            </TableRow>

            {hasBeds && (
                <TableRow>
                    <TableCell colSpan={4} sx={{ padding: 0 }}>
                        <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
                            <Table size="small">
                                {item.beds.map(({ bedName, bedStatus, bedType, roomName }) => {
                                    return (
                                        <TableRow key={`${roomName}-${bedName}`}>
                                            <TableCell
                                                colSpan={2}
                                                sx={{
                                                    backgroundColor: '#f3f2f2',
                                                    width: '45%',
                                                    paddingLeft: '75px',
                                                }}
                                            >
                                                Room Name: {roomName} / Bed Name: {bedName}
                                            </TableCell>
                                            <TableCell
                                                sx={{ backgroundColor: '#f3f2f2', width: '38%' }}
                                            >
                                                Bed Type: {bedType}
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: '#f3f2f2' }}>
                                                {bedStatus}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default JuvarePage;
