import { Box, Stack } from '@mui/material';
import React from 'react';

export const FullHeightStack = ({ children, ...props }) => (
    <Stack {...props} height="100%">
        {children}
    </Stack>
);

export const FullHeightBox = ({ children, ...props }) => (
    <Box 
        {...props} 
        display="flex" 
        flexDirection="column" 
        flexGrow={1} 
        overflow="auto"
    >
        {children}
    </Box>
);