import { useSelector } from 'react-redux';
import { FullHeightBox, FullHeightStack } from '../../components/FullHeightStack/FullHeightStack';
import {
    Alert,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { format } from 'date-fns/format';
import { Suspend } from '../../components/Suspend';
import { useSearchParams } from 'react-router-dom';
import { LogsFilters } from './LogsFilters';

const LogsPage = () => {
    const { isLoading, items } = useSelector((state) => state.logs);
    const [searchParams] = useSearchParams();
    const siteId = searchParams.get('siteId');

    return (
        <FullHeightStack spacing={2}>
            <Box spacing={12}>
                <LogsFilters />
            </Box>
            <Suspend isLoading={isLoading}>
                <FullHeightBox>
                    {!isLoading && !!siteId && (
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>facilityName</TableCell>
                                        <TableCell>floorName</TableCell>
                                        <TableCell>roomName</TableCell>
                                        <TableCell>bedName</TableCell>
                                        <TableCell>activitySource</TableCell>
                                        <TableCell>activityName</TableCell>
                                        <TableCell>changedAt</TableCell>
                                        <TableCell>details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items?.map((item) => (
                                        <ItemRow key={item?.bedName} item={item} />
                                    ))}
                                    {items?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                                                No results were found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {!siteId && (
                        <Alert severity="info">Please select a site to view related bed logs</Alert>
                    )}
                </FullHeightBox>
            </Suspend>
        </FullHeightStack>
    );
};

const ItemRow = ({ item: originalItem }) => {
    const item = {
        ...originalItem,
        details: originalItem.details === 'null' ? '' : originalItem.details,
    };

    return (
        <TableRow>
            <TableCell>{item?.facilityName}</TableCell>
            <TableCell>{item?.floorName}</TableCell>
            <TableCell>{item?.roomName}</TableCell>
            <TableCell>{item?.bedName}</TableCell>
            <TableCell>{item?.activitySource}</TableCell>
            <TableCell>{item?.activityName}</TableCell>
            <TableCell>{format(item?.changedAt, 'M/d/yyyy h:mm a')}</TableCell>
            <TableCell>{item?.details}</TableCell>
        </TableRow>
    );
};

export default LogsPage;
