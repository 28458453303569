import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import bedService from '../../services/BedService';

export const findBedLogs = createAsyncThunk(
    'findBedLogs',
    handleApiCall((data) => bedService.findBedLogs(data)),
);

const initialState = {
    items: [],
    isLoading: false,
    page: 1,
    totalPageCount: null,
};

const slice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        setItems(state, action) {
            state.items = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        reset() {
            return {...initialState }
        }
    },
    extraReducers: {
        [findBedLogs.pending]: (state) => {
            state.isLoading = true;
        },
        [findBedLogs.fulfilled]: (state, action) => {
            const { items, page, totalPageCount } = action.payload;
            state.items = items;
            state.page = page;
            state.totalPageCount = totalPageCount;
            state.isLoading = false;
        },
        [findBedLogs.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const { setPage, setItems, reset } = slice.actions;

const logsSlice = { reducer: slice.reducer, initialState };
export default logsSlice;
