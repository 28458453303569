import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

export const MuiThemeProvider = ({ children }) => (
    <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    </>
);