import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef } from 'react';

const isPrimitive = (val) => val == null || /^[sbn]/.test(typeof val);

const checkDependencies = (deps) => {
    if (!deps || deps.length === 0)
        throw new Error(
            'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.',
        );
    if (deps.every(isPrimitive))
        throw new Error(
            'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
        );
};


/* eslint-disable */
const useDeepCompareMemoize = (value) => {
    const ref = useRef(value);
    const signalref = useRef(0);

    if (!isEqual(value, ref.current)) {
        ref.current = value;
        signalref.current += 1;
    }

    return useMemo(() => ref.current, [signalref.current]);
};

/**
 * Use it in place of React.useEffect when objects are in the depenency
 * array. Does a deep comparison and the callback is only run when the
 * variables actually change.
 * @see https://github.com/kentcdodds/use-deep-compare-effect - copied
 * from here but modified to use lodash.
 * @param {Function} callback
 * @param {Array} dependencies
 */
export const useDeepCompareEffect = (callback, dependencies) => {
    if (process.env.NODE_ENV !== 'production') {
        checkDependencies(dependencies);
    }
    useEffect(callback, [useDeepCompareMemoize(dependencies)]);
};

/* eslint-enable */