const defaultErrorMessage =
    'The server has indicated that an error occurred while processing your request.';
const separatorStr = ', ';

/**
 * Represents an API validation error.
 */
export default class ValidationError extends Error {
    name = 'ValidationError';

    /**
     * Constructs a new instance of a ValidationError object.
     * @param {Object} result - object of the shape { wasSuccessful, validationErrors }
     * @param {string} message - optional message to use instead of strings from validationErrors
     */
    constructor(result, message = null) {
        message = message || getErrorMessageFromApiResult(result);
        super(message);

        // maintains proper stack trace for where our error was thrown (not available in IE)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }

        // save result for reference
        this.result = result;
    }

    /**
     * Returns true if the result has a generalized error message that
     * isn't specific to a particular field. Otherwise, returns false.
     * @param {Object} result - object of the shape { wasSuccessful, validationErrors }
     */
    static resultHasGeneralError(result) {
        if (!result) return false;
        return (
            result.message ||
            result.errorMessage ||
            result.general ||
            result.validationErrors?.general ||
            (result.validationErrors && result.validationErrors[''])
        );
    }
}

function getErrorMessageFromApiResult(result) {
    if (!result) return defaultErrorMessage;

    const { validationErrors, errorMessage, message } = result;
    // in some legacy instances the API will return result.errorMessage or
    // result.message instead of a validationErrors object
    if (errorMessage) return errorMessage;
    if (message) return message;
    if (!result.validationErrors) return defaultErrorMessage;

    // this is the standard generic error message key
    if (validationErrors.general) return validationErrors.general;
    // in some legacy instances the API will return a generic message with no key
    if (validationErrors['']) return validationErrors[''];

    const validationErrorsValues = Object.values(validationErrors);

    if (validationErrorsValues.length === 0) return defaultErrorMessage;
    if (validationErrorsValues.length === 1) return validationErrorsValues[0];

    return validationErrorsValues.join(separatorStr);
}
