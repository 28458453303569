import React, { useMemo, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { addDays, subDays, startOfDay, endOfDay, parse } from 'date-fns';
import { format } from 'date-fns/format';

export const DateRangePicker = ({ value, onChange, maxDifferenceOfDays = 14 }) => {
    const [dateRange, setDateRange] = useState({
        startDate: parseDate(value?.startDate),
        endDate: parseDate(value?.endDate),
    });

    const handleStartDateChange = (newStartDate) => {
        let updatedEndAt = dateRange?.endDate;

        if (!updatedEndAt) {
            updatedEndAt = addDays(newStartDate, maxDifferenceOfDays);
        }

        const data = {
            startDate: newStartDate,
            endDate: updatedEndAt,
        };

        setDateRange(data);
        onChange(formatDate(data));
    };

    const handleEndDateChange = (newEndDate) => {
        let updatedStartAt = dateRange?.startDate;

        if (!dateRange?.startDate) {
            updatedStartAt = subDays(newEndDate, maxDifferenceOfDays);
        }

        const data = {
            startDate: updatedStartAt,
            endDate: newEndDate,
        };

        setDateRange(data);
        onChange(formatDate(data));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" gap={2}>
                <DatePickerInput
                    key="StartDate"
                    label="Start Date"
                    value={dateRange?.startDate}
                    maxDate={dateRange?.endDate}
                    onChange={handleStartDateChange}
                />

                <DatePickerInput
                    key="EndDate"
                    label="End Date"
                    value={dateRange?.endDate}
                    onChange={handleEndDateChange}
                    minDate={dateRange?.startDate}
                    maxDate={
                        dateRange?.startDate
                            ? addDays(dateRange?.startDate, maxDifferenceOfDays)
                            : undefined
                    }
                />
            </Box>
        </LocalizationProvider>
    );
};
// Components Helpers
// The validation follow the documentation Pattern.
// https://mui.com/x/react-date-pickers/validation/
const DatePickerInput = (props) => {
    const [error, setError] = React.useState(null);
    const prefix = useMemo(() => {
        switch (error) {
            case 'invalidDate':
            case 'maxDate':
            case 'minDate': {
                return 'Invalid';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <DatePicker
            {...props}
            label={`${prefix}${props?.label}`}
            onError={(error) => setError(error)}
            slotProps={{ textField: { size: 'small' } }}
        />
    );
};

// Helpers
const formatDate = ({ startDate, endDate }) => {
    return {
        startDate: applyDate(startOfDay(startDate)) ?? null,
        endDate: applyDate(endOfDay(endDate)) ?? null,
    };
};

const applyDate = (value) => {
    if (!value || isNaN(new Date(value).getTime())) return null;

    return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
};

const parseDate = (value) => {
    if (!value) return null;

    const date = parse(value, 'MM/dd/yyyy HH:mm:ss', new Date());

    if (isNaN(date.getTime())) return null;

    return date;
};
