import { LogLevel } from "@azure/msal-browser";

/**
 * MSAL configuration options.
 * See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: window.environment.AZUREAD_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${window.environment.AZUREAD_TENANT_ID}`,
        // the URL the user will be redirected to after successful login
        redirectUri: `${window.environment.APP_URL}/callback`,
        // disable default behavior of using the current URL, forces all AD responses
        // to come to /callback
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }	
                if (process.env.NODE_ENV !== 'production') {	
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }	
                }
            }	
        }	
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [`api://${window.environment.AZUREAD_CLIENT_ID}/access_as_user`],
    forceRefresh: true, // forces reftresh token even if already authenticated
    refreshTokenExpirationOffsetSeconds: 7200, // 2hr
};

//subsequent calls will fetch refresh token from cache, and only refresh if offset is met
export const apiRequest = {
    scopes: [`api://${window.environment.AZUREAD_CLIENT_ID}/access_as_user`],
    refreshTokenExpirationOffsetSeconds: 7200, // 2hr
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};