import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import { RequiresAuth } from './features/auth';
import MainLayout from './layouts/MainLayout';
import SlimLayout from './layouts/SlimLayout';
import FindBedsPage from './pages/FindBeds/FindBedsPage';
import ManageBedsPage from './pages/ManageBeds/ManageBedsPage';
import SiteConfigurationPage from './pages/SiteConfiguration/SiteConfigurationPage';
import AuthCallbackPage from './pages/AuthCallback/AuthCallbackPage';
import JuvarePage from './pages/Juvare/JuvarePage';
import LogsPage from './pages/Logs/LogsPage';

const AppRouter = () => {
    return (
        <BrowserRouter basename="/bedavailability">
            <Routes>
                <Route index element={(
                    <MainLayout title="Bed Availability » Find Beds">
                        <FindBedsPage />
                    </MainLayout>
                )} />
                <Route path="/find" element={(
                    <MainLayout title="Bed Availability » Find Beds">
                        <FindBedsPage />
                    </MainLayout>
                )} />
                <Route path="/manage/*" element={(
                    <RequiresAuth>
                        <MainLayout title="Bed Availability » Manage Beds">
                            <ManageBedsPage />
                        </MainLayout>
                    </RequiresAuth>
                )} />
                <Route path="/config/*" element={(
                    <RequiresAuth>
                        <MainLayout title="Bed Availability » Site Configuration">
                            <SiteConfigurationPage />
                        </MainLayout>
                    </RequiresAuth>
                )} />
                <Route path="/callback" element={(
                    <SlimLayout title="Bed Availability » Loading...">
                        <AuthCallbackPage />
                    </SlimLayout>
                )} />
                <Route path="/juvare" element={(
                    <RequiresAuth>
                        <MainLayout title="Bed Availability » Juvare">
                            <JuvarePage />
                        </MainLayout>
                    </RequiresAuth>
                )} />
                <Route path="/logs" element={(
                    <RequiresAuth>
                        <MainLayout title="Bed Availability » Logs">
                            <LogsPage />
                        </MainLayout>
                    </RequiresAuth>
                )} />
                <Route path="*" element={(
                    <Navigate to="/find" />
                )} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
