import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientsAutocomplete } from '../../components/ClientsAutocomplete';

export const JuvareFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialClientId = searchParams.get('siteId');

    const [client, setClient] = useState(null);
    const siteId = client?.siteId;

    useEffect(() => {
        if(siteId && siteId !== parseInt(initialClientId)) {
            setSearchParams({ siteId });
        }

        if(!siteId) {
            setSearchParams({});
        }
    }, [siteId, initialClientId, setSearchParams]);

    const handleChange = useCallback((newClient) => setClient(newClient), []);

    return (
        <Stack direction="row" spacing={2}>
            <ClientsAutocomplete
                onChange={handleChange}
                value={client}
                initialClientId={initialClientId}
            />
        </Stack>
    );
};
