import React, { useState } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, CircularProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';
import './DragAndDropUploadZone.scss';

export const DragAndDropUploadZone = ({ onDrop }) => {
    const { getRootProps, getInputProps, open, isDragAccept } =
        useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            onDrop: async (files) => {
                setIsUploading(true);
                await onDrop(files);
                setIsUploading(false);
            },
            accept: {
                'text/csv': ['.csv'],
            },
            maxFiles: 1,
        });

    const [isUploading, setIsUploading] = useState(false);

    return (
        <section
            className={classNames('dropzone__container', {
                'dropzone__container--drag-accept': isDragAccept,
            })}
        >
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {!isUploading ? (
                    <>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ marginBottom: '20px', color: '#1976d2' }}
                        >
                            Click and Drag to begin Upload or
                        </Typography>
                        <Button
                            aria-label="Upload Configuration"
                            color="primary"
                            onClick={open}
                            variant="contained"
                            startIcon={<UploadIcon />}
                        >
                            Select File
                        </Button>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </section>
    );
};
