import { getStoredApiAccessToken } from '../features/auth';
import ApiService from './ApiService';

class ConfigService extends ApiService {
    async uploadConfig(data, files, onProgress) {
        const url = `/api/v1/sites/${data.siteId}/configuration/upload`

        return await this.postMultipartForm(url, data, files, onProgress);
    }

    async loadSites(data) {
        const url = `/api/v1/sites`;
        return await this.get(url, data);
    }

    async validateConfig(data) {
        const { siteId, fileReference, config } = data;
        const url = `/api/v1/sites/${siteId}/configuration/${fileReference}/validate`
        return await this.post(url, config);
    }

    async finalizeConfig(data) {
        const { siteId, fileReference, config } = data;
        const url = `/api/v1/sites/${siteId}/configuration/${fileReference}`
        return await this.post(url, config);
    }
    
}

const configService = new ConfigService();
configService.setHost(window.environment.API_URL);
configService.setTokenResolver(getStoredApiAccessToken);

export default configService;