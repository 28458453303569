const errorMessage =
    'Sorry, there was a problem fulfilling your request. Please try again and if the problem persists, contact support.';

/**
 * Custom error that represents an error returned from the server during an API call.
 */
export default class ServerError extends Error {
    static DefaultErrorMessage = errorMessage;

    name = 'ServerError';

    /**
     * Constructs a new instance of a ServerError object.
     * @param {Object} response - Fetch Response interface (see https://developer.mozilla.org/en-US/docs/Web/API/Response)
     */
    constructor(response) {
        super(errorMessage);

        // maintains proper stack trace for where our error was thrown (not available in IE)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError);
        }

        this.json = null;
        this.status = response?.status;
        this.statusText = response?.statusText;
        this.url = response?.url;
        this.serverStackTrace = null;
        this.responseJson = undefined;

        if (
            response?.json &&
            response?.headers &&
            response?.headers.get('Content-Type') === 'application/json'
        ) {
            this.json = async () => {
                // if already parsed, don't parse again
                if (this.responseJson !== undefined) return this.responseJson;
                let responseJson = null;
                try {
                    responseJson = await response.json(); // this is a function that returns a promise
                } catch {
                    console.warn('ServerError: Failed to parse response JSON.');
                }
                this.responseJson = responseJson;
                return this.responseJson;
            };
        }

        this.getServerStackTrace = async () => {
            if (!this.json) return this.serverStackTrace;

            // parse stacktrace from the fetch API response object
            const result = await this.json();
            if (result.wasSuccessful === false && result.message)
                this.serverStackTrace = result.message;

            return this.serverStackTrace;
        };
    }
}
