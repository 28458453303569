import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleApiCall } from '../../helpers/handleApiCall';
import configService from '../../services/ConfigService';

export const loadSites = createAsyncThunk(
    'loadSites',
    handleApiCall((data) => configService.loadSites(data)),
);

const initialState = {
    selectedSite: {
        isConfigured: null,
        siteId: null,
        name: null,
    },
    sites: [],
    isLoading: true,
    page: 1,
    totalPageCount: null,
    columns: null,
};

const slice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setSelectedSite(state, action) {
            state.selectedSite = action.payload;
        },
        reset() {
            return {...initialState }
        }
    },
    extraReducers: {
        [loadSites.pending]: (state) => {
            state.isLoading = true;
        },
        [loadSites.fulfilled]: (state, action) => {
            const { items, page, totalPageCount } = action.payload;
            state.sites = items;
            state.page = page;
            state.totalPageCount = totalPageCount;
            state.isLoading = false;
        },
        [loadSites.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const { setPage, setSelectedSite, reset } = slice.actions;

const sitesSidebarSlice = { reducer: slice.reducer, initialState };
export default sitesSidebarSlice;
